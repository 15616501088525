<template>
  <div class="container mb-5">
    <b-col
      lg="10"
      sm="12"
      xs="12"
      md="10"
      offset-lg="1"
      offset-md="1"
    >
      <b-card
        class="hubu-bg-red"
        :header="appText('manage_accounts_title')"
        header-tag="header"
      >
        <b-row v-if="authorizations && authorizations.addUser">
          <b-col
            lg="12"
            sm="12"
            class="text-right"
          >
            <router-link
              tag="b-button"
              :to="{name: 'AddOrganizationMember'}"
            >
              {{ $t('organization.add_member') }}
            </router-link>
          </b-col>
        </b-row>

        <h3 class="text-center mt-3 mb-5">
          {{ $t('organization.my_users') }}
        </h3>

        <template v-if="typeof organizations.hats != 'undefined'">
          <template v-if="organizations.hats.length > 0">
            <b-row
              v-for="(hat, index) in organizations.hats"
              :key="index"
              class="p-1"
            >
              <b-col
                lg="6"
                sm="6"
              >
                <font-awesome-icon
                  v-if="hat.user.confirmed"
                  :class="icon(hat.user).class"
                  :icon="{ prefix: 'fa', iconName: icon(hat.user).icon }"
                />
                <svg
                  v-else
                  class="svg-icon icon"
                  viewBox="0 0 20 20"
                  style="fill: red; stroke: red;"
                >
                  <path d="M10.185,1.417c-4.741,0-8.583,3.842-8.583,8.583c0,4.74,3.842,8.582,8.583,8.582S18.768,14.74,18.768,10C18.768,5.259,14.926,1.417,10.185,1.417 M10.185,17.68c-4.235,0-7.679-3.445-7.679-7.68c0-4.235,3.444-7.679,7.679-7.679S17.864,5.765,17.864,10C17.864,14.234,14.42,17.68,10.185,17.68 M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
                </svg>
                <router-link
                  class="text-blue"
                  tag="a"
                  :to="{ name: 'ManageAccount', params: { user: hat.user } }"
                >
                  {{ hat.user.first_name }} {{ hat.user.last_name }}
                </router-link>
              </b-col>   
              <b-col
                lg="6"
                sm="6"
              >
                {{ $t('organization.games_played') }}: {{ gamesPerHat[hat.id] || 0 }}
              </b-col>
              <b-col
                lg="12"
                sm="12"
              >
                <hr />
              </b-col>
            </b-row>
          </template>

          <template v-else>
            <b-row>
              <b-col
                lg="12"
                md="12"
              >
                {{ $t('organization.no_members') }}
              </b-col>
            </b-row>
          </template>
        </template>
      </b-card>
    </b-col>

    <b-modal
      id="confirmation-modal"
      :title="$t('modal.confirm.title')"
      header-bg-variant="red"
      header-text-variant="white"
      body-bg-variant="white"
      body-text-variant="dark"
      footer-bg-variant="white"
      footer-text-variant="red"
    >
      <b-container fluid>
        <b-row class="mb-1 text-center">
          <b-col cols="12">
            {{ $t("organizations.member_remove") }}
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <b-button>
            {{ $t("no") }}
          </b-button>

          <b-button
            variant="outline-red"
            size="lg"
            class="float-right"
            @click="proceedToDelete"
          >
            {{ $t("yes") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManageAccounts",

  data () {
    return {
      Organizations: {},
      toRemove: null,
      fields: [{ label: "First name" }, { label: "Last name" }],
    };
  },

  computed: {
    ...mapGetters(["authorizations", "gamesPerHat"]),
    organizations: {
      get () {
        return this.Organizations;
      },
      set (v) {
        this.Organizations = v;
      },
    },
  },

  mounted () {
    this.$store.dispatch("getOrganizations").then(() => {
      if (typeof this.$store.getters.getOrganizations == "object") {
        this.organizations = this.$store.getters.getOrganizations[0];
      }    
    });
  },

  methods: {
    icon (user) {
      return {
        icon: user.confirmed ? "check" : "x-mark",
        class: `icon ${user.confirmed ? "green" : "red"}`,
      };
    },
    fetchOrganization () {
      this.$store.dispatch("getOrganizations").then(() => {
        this.organizations = this.$store.getters.getOrganizations;
      });
    },

    proceedToDelete () {
      this.$bvModal.hide("confirmation-modal");
      this.$store.dispatch("deleteOrganizationMember", {
        id: this.organization.id,
        member: this.toRemove,
      });
    },

    promptUserDelete (id) {
      this.toRemove = id;
      this.$bvModal.show("confirmation-modal");
    },

    updateOrganization () {
      this.$store
        .dispatch("updateOrganization", {
          id: this.organization.id,
          name: this.organization.Name,
        })
        .then(() => {
          this.$toast.open("Saved");
        });
    },

    appText (text) {
      return typeof this.$store.getters.getAppTexts[text] == "object"
        ? this.$store.getters.getAppTexts[text].content
        : text;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.hubu-bg-red {
  border: 1px solid $red;
}

.hubu-btn-red {
  background-color: $red;
  color: $white;
  font-weight: bold;
  border: 0px;
}

.btn-white {
  background-color: $white;
  color: $red;
  font-weight: bold;
  border: 1px solid $red;
}

header {
  background-color: $red;
  border-color: $red;
  font-size: 24px;
  color: $orange;
  font-weight: bold;
}

.text-blue {
  color: rgb(74, 113, 241) !important;
}

.icon {
  margin-left: 12px;
  margin-right: 16px;
  font-size: 12px;
  width: 24px;
  height: 24px;
}

.green {
  color: green;
}


.red {
  color: green;
}
</style>
